import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { markActiveInActiveCampaignSuccess,markActiveInActiveCampaignFailure  } from '../../actions/campaignActions/markActiveInActiveCampaignActions';
import store from '../../configStore';

function* markActiveInActiveCampaign({ payload }) {
    console.log('====================================');
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.markActiveInActiveCampaign,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_SUCCESS status :", response.data.status);
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_SUCCESS data :", response.data.response);
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            markActiveInActiveCampaignSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_FAILURE status :", error?.response?.status);
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_FAILURE data:", error?.response?.data);
        console.log("MARK_ACTIVE_INACTIVE_CAMPAIGN_FAILURE error :", error);
        console.log('====================================');
        yield put(markActiveInActiveCampaignFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* markActiveInActiveCampaignSaga() {
    yield takeLatest(actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_REQUEST, markActiveInActiveCampaign);
}

export default markActiveInActiveCampaignSaga;
