export const base_url = "https://dev.paidpromo.ai/api" // User Management Service Base URL


export const endpoints = {
    GenerateOTP: "OTP/GenerateOTP", // Generate OTP
    login: "/paidpromo/auth/adminLogin",


//     influencer
    getInfluencerListByStatus: "/paidpromo/influencer/getInfluencerListByStatus?status=",
    markApprovedRejectedInfluencer: "/paidpromo/influencer/markApprovedRejectedInfluencer",

    // brand
    getBrandListByStatus: "/paidpromo/brand/getBrandListByStatus?status=",
    markApprovedRejectedBrand:"/paidpromo/brand/markApprovedRejectedBrand",

//     campaign
    getCampaignListByStatus : "/paidpromo/campaign/getCampaigListByFilter",
    markApprovedRejectedCampaign: "/paidpromo/campaign/markApprovedRejectedCampaigns",
    getCampaignById :"/paidpromo/campaign/getCampaignById/",

//     Rate card
    getAllRateCard : "/paidpromo/rateCard/getAllRateCard",
    createRateCard: "/paidpromo/rateCard/createRateCard",
    updateRateCard :"/paidpromo/rateCard/updateRateCard",

//     Config Properties
    getAllConfigProperties: "/paidpromo/configProperties/getAllConfigProperties",
    updateConfigPropertiesById: "/paidpromo/configProperties/updateConfigPropertiesById",

//     public urls
    getAllRegion: "/paidpromo/region/getAllRegion",
    getAllCountry: "/paidpromo/country/getAllCountry",
    getAllState:   "/paidpromo/state/getAllStateForAdmin",
    getAllAgeGroup: "/paidpromo/ageGroup/getAllAgeGroup",
    getAllNiche: "/paidpromo/niche/getAllNiche",

//     user list
    getUsersListWithFilters: "/paidpromo/user/getUsersListWithFilters",
    markActiveInActiveUser: "/paidpromo/user/markActiveInActiveUser",

    getCampaignListForMarkDisabled: "/paidpromo/campaign/getCampaignListForMarkDisabled",
    markActiveInActiveCampaign: "/paidpromo/campaign/markActiveInActiveCampaign",


}
