import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import { campaignList, campaignListReset } from "../../store/actions/campaignActions/campaignListActions";
import {
    approvedRejectedCampaign,
    approvedRejectedCampaignReset
} from "../../store/actions/campaignActions/approvedRejectedCampaignActions";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Link} from "react-router-dom";
import {MyCampaignsImg} from "../../static/constant/imgConstants";

Modal.setAppElement('#root');

function Campaign() {
    const dispatch = useDispatch();
    const campaignReducer = useSelector(state => state.campaignListReducers);
    const approvedRejectedCampaignReducer = useSelector(state => state.approvedRejectedCampaignReducers);

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [statusFilter, setStatusFilter] = useState('PENDING_APPROVAL');
    const [campaignTypeFilter, setCampaignTypeFilter] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [approveFlag, setApproveFlag] = useState(true);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        dispatch(campaignList({} ));
    }, []);

    const applyDateFilter = () => {
        if(statusFilter == 'PENDING_APPROVAL'){
            setApproveFlag(true);
        } else {
            setApproveFlag(false);
        }
        dispatch(campaignList({
            status: statusFilter ,
            campaignType: campaignTypeFilter,
            startDate,
            endDate,
        }));
    };

    useEffect(() => {
        if (campaignReducer?.status === 200) {
            console.log("campaign data", campaignReducer.data);

            const sorted = [...campaignReducer.data].sort((a, b) => b.campaignId - a.campaignId); // Sort data by campaignId descending
            setSortedData(sorted);

            dispatch(campaignListReset());
        } else if (campaignReducer?.status) {
            dispatch(campaignListReset());
        }
    }, [campaignReducer]);

    useEffect(() => {
        if (approvedRejectedCampaignReducer?.status === 200) {
            dispatch(campaignList({ statusFilter: statusFilter }));
            dispatch(approvedRejectedCampaignReset());
        } else if (approvedRejectedCampaignReducer?.status) {
            dispatch(approvedRejectedCampaignReset());
        }
    }, [approvedRejectedCampaignReducer]);

    const openApproveModal = (data) => {
        setSelectedCampaign(data);
        setIsApproveModalOpen(true);
    };

    const openRejectModal = (data) => {
        setSelectedCampaign(data);
        setIsRejectModalOpen(true);
    };

    const closeApproveModal = () => {
        setIsApproveModalOpen(false);
        setSelectedCampaign(null);
    };

    const closeRejectModal = () => {
        setIsRejectModalOpen(false);
        setSelectedCampaign(null);
    };

    const handleApprove = () => {
        if (selectedCampaign) {
            dispatch(approvedRejectedCampaign([{
                "campaignId": selectedCampaign.campaignId,
                "status": "APPROVED"
            }]));
            closeApproveModal();
        }
    };

    const handleReject = () => {
        if (selectedCampaign) {
            dispatch(approvedRejectedCampaign([{
                "campaignId": selectedCampaign.campaignId,
                "status": "REJECTED"
            }]));
            closeRejectModal();
        }
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };
    const handleCampaignFilterChange = (e) => {
        setCampaignTypeFilter(e.target.value);
    };

    const columns = [
        {
            name: 'Campaign Id',
            selector: row => row.campaignId,
            sortable: true,
        },
        {
            name: 'Brand Name',
            selector: row => `${row.brand?.wallet?.user?.firstName} ${row.brand?.wallet?.user?.lastName}`,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Purpose',
            selector: row => row.purpose,
            sortable: true,
        },
        {
            name: 'Campaign Type',
            selector: row => row.campaignType,
            sortable: true,
        },
        {
            name: 'Platform',
            selector: row => row.platform,
            sortable: true,
        },
        {
            name: 'Referral Code',
            selector: row => row.referralCode,
            sortable: true,
        },
        {
            name: 'Google Link',
            cell: row => (
                <a
                    href={row.googleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                >
                    {row.googleLink}
                </a>
            ),
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Target Views',
            selector: row => row.targetViews,
            sortable: true,
        },
        {
            name: 'Estimated Budget',
            selector: row => row.estimatedBudget,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.campaignStatus,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country?.countryName ? row.country?.countryName : '----',
            sortable: true,
        },
        {
            name: 'Payment Status',
            selector: row => row.paymentStatus ? row.paymentStatus : '----',
            sortable: true,
        },
        {
            name: 'Details',
            cell: row => (
                <Link
                    to={`/campaigndetails/${row.campaignId}`}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-xl"
                >
                     Details
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        ...(approveFlag
            ? [{
                name: 'Actions',
                width: '200px',
                cell: row => (
                    <div className="flex items-center justify-center space-x-2 w-full">
                        <button
                            onClick={() => openApproveModal(row)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Approve
                        </button>
                        <button
                            onClick={() => openRejectModal(row)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Reject
                        </button>
                    </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }]
            : []),
    ];

    return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen p-8 font-sans">
            {/* Header */}
            <div className="flex items-center justify-between bg-white rounded-lg p-6 shadow-md mb-6">
                <h1 className="m-0 text-[2.4rem] font-bold text-gray-800">Campaign</h1>

            </div>

            {/* Filters Row */}
            <div className="flex text-2xl  items-center space-x-4 mb-6">
                <div className="flex  text-2xl items-center space-x-4 mb-4">
                    {/* Status Filter */}
                    <div className="flex items-center">
                        <label className="mr-2 font-semibold text-gray-700">Status:</label>
                        <select
                            value={statusFilter}
                            onChange={handleStatusFilterChange}
                            className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                        >
                            <option value="PENDING_APPROVAL">PENDING_APPROVAL</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="REJECTED">REJECTED</option>
                            <option value="DRAFT">DRAFT</option>
                        </select>
                    </div>

                    {/* Campaign Type Filter */}
                    <div className="flex items-center">
                        <label className="mr-2 font-semibold text-gray-700">Campaign Type:</label>
                        <select
                            value={campaignTypeFilter}
                            onChange={handleCampaignFilterChange}
                            className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                        >
                            <option value="">ALL</option>
                            <option value="BANNER">BANNER</option>
                            <option value="VIDEO">VIDEO</option>
                            <option value="UGC">UGC</option>
                        </select>
                    </div>

                    {/* Date Pickers */}
                    <div className="flex items-center">
                        <label htmlFor="datetime" className="mr-2 font-semibold text-gray-700">Start Date:</label>
                        {/*<DatePicker*/}
                        {/*    selected={startDate}*/}
                        {/*    onChange={(date) => setStartDate(date)}*/}
                        {/*    selectsStart*/}
                        {/*    startDate={startDate}*/}
                        {/*    endDate={endDate}*/}
                        {/*    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"*/}
                        {/*    isClearable*/}
                        {/*    placeholderText="Select start date"*/}
                        {/*/>*/}
                        <input
                            type="date"
                            id="datetime"
                            placeholder="Enter Start Date"
                            className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                            value={startDate}
                            onChange={(e) => {setStartDate(e.target.value); setEndDate("") }}

                        />
                        {/*<img*/}
                        {/*    src={MyCampaignsImg.calendarIcon}*/}
                        {/*    alt="Calendar Icon"*/}
                        {/*    className="absolute right-3 top-1/2 transform -translate-y-1/2 mt-4 w-10 h-10"*/}
                        {/*    onKeyDown={(e) => e.preventDefault()}*/}
                        {/*    onClick={() => {*/}
                        {/*        const inputDateElement = document.getElementById('datetime');*/}
                        {/*        inputDateElement.showPicker();*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>

                    <div className="flex items-center">
                        <label htmlFor="datetimeend" className="mr-2 font-semibold text-gray-700">End Date:</label>
                        {/*<DatePicker*/}
                        {/*    selected={endDate}*/}
                        {/*    onChange={(date) => setEndDate(date)}*/}
                        {/*    selectsEnd*/}
                        {/*    startDate={startDate}*/}
                        {/*    endDate={endDate}*/}
                        {/*    minDate={startDate}*/}
                        {/*    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"*/}
                        {/*    isClearable*/}
                        {/*    placeholderText="Select end date"*/}
                        {/*    size="lg"*/}
                        {/*/>*/}
                        <input
                            type="date"
                            id="datetimeend"
                            min={startDate}
                            placeholder="Enter End Date"
                            disabled={!startDate}
                            className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}

                        />
                    </div>

                    <button
                        onClick={applyDateFilter}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Apply Filters
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">

                {Array.isArray(campaignReducer?.data) && campaignReducer.data.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={sortedData}
                        pagination
                        highlightOnHover
                        theme="default"
                        customStyles={{
                            rows: {
                                style: {
                                    fontSize: '16px',
                                    color: '#4a4a4a',
                                    fontWeight: 500,
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    backgroundColor: '#f1f5f9',
                                    color: '#2c3e50',
                                },
                            },
                        }}
                    />
                ) : (
                    <div className="text-gray-600 text-[2.5rem] text-center mt-8">
                        No records to display.
                    </div>
                )}
                </div>

                <Modal
                    isOpen={isApproveModalOpen}
                    onRequestClose={closeApproveModal}
                    contentLabel="Confirm Approve"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width:'500px'
                        },
                    }}
                >
                    <div className="text-[2rem] mb-5 font-bold">Confirm Approval</div>
                    <p className="text-[2rem] mb-5">Are you sure you want to approve this campaign?</p>
                    <div className="mt-3 float-right">
                        <button onClick={handleApprove}
                                className="bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                            Yes
                        </button>
                        <button onClick={closeApproveModal}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                            No
                        </button>
                    </div>
                </Modal>

                {/* Rejection Confirmation Modal */}
                <Modal
                    isOpen={isRejectModalOpen}
                    onRequestClose={closeRejectModal}
                    contentLabel="Confirm Reject"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width:'500px'
                        },
                    }}
                >
                    <div className="text-[2rem] mb-5 font-bold">Confirm Rejection</div>
                    <p className="text-[2rem] mb-5">Are you sure you want to reject this campaign?</p>
                    <div className="mt-3 float-right">
                        <button onClick={handleReject}
                                className="bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                            Yes
                        </button>
                        <button onClick={closeRejectModal}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                            No
                        </button>
                    </div>
                </Modal>
            </div>
            );
            }

            export default Campaign;
