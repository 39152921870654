import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const markActiveInActiveCampaign = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const markActiveInActiveCampaignSuccess = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const markActiveInActiveCampaignFailure = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_FAILURE,
    payload,
});

export const markActiveInActiveCampaignReset = () => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const markActiveInActiveCampaignResetAll = () => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_CAMPAIGN_RESET_ALL,
});
