import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const campaignListForDisabled = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FOR_MARK_DISABLED_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const campaignListForDisabledSuccess = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FOR_MARK_DISABLED_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const campaignListForDisabledFailure = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FOR_MARK_DISABLED_FAILURE,
    payload,
});

export const campaignListForDisabledReset = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FOR_MARK_DISABLED_RESET,
});

// Action creator for resetting all OTP generation reducer
export const campaignListForDisabledResetAll = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FOR_MARK_DISABLED_RESET_ALL,
});
