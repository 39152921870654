import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const markUserStatus = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_USER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const markUserStatusSuccess = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_USER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const markUserStatusFailure = (payload) => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_USER_FAILURE,
    payload,
});

export const markUserStatusReset = () => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_USER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const markUserStatusResetAll = () => ({
    type: actionTypes.MARK_ACTIVE_INACTIVE_USER_RESET_ALL,
});
