import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import {rateCardCreate, rateCardCreateReset} from '../../store/actions/rateCardActions/rateCardCreateActions';
import {rateCardUpdate, rateCardUpdateReset} from '../../store/actions/rateCardActions/rateCardUpdateActions';
import {getAllAgeGroupList, getAllAgeGroupListReset} from "../../store/actions/publicActions/getAllAgeGroupActions";
import {getAllRegionList} from "../../store/actions/publicActions/getAllRegionsActions";
import {getAllStateList, getAllStateListReset} from "../../store/actions/publicActions/getAllStateActions";
import {getAllCountryList, getAllCountryListReset} from "../../store/actions/publicActions/getAllCountryActions";
import {getAllNicheList, getAllNicheListReset} from "../../store/actions/publicActions/getAllNicheActions";
import {useNavigate} from "react-router";
import {useToast} from "../../hooks/useToast";

function RateCardForm() {
    const dispatch = useDispatch();
    const location = useLocation();
    const Toast = useToast();
    const navigate = useNavigate();

    const { row } = location.state || {};

    const [mode , setMode] = useState('create')

    const [rateCardData, setRateCardData] = useState({
        name: '',
        platform: '',
        campaignType: '',
        countryId: '',
        stateId: '',
        ageGroupId: '',
        nicheId: '',
        costPer1k: '',
        brandPenaltyPercent: '',
        rateCardId: ''
    });

    const countries = useSelector((state) => state.getAllCountryReducers );
    const states = useSelector((state) => state.getAllStateReducers );
    const ageGroups = useSelector((state) => state.getAllAgeGroupReducers );
    const niches = useSelector((state) => state.getAllNicheReducers );
    const updateRateCardData = useSelector((state) => state.rateCardUpdateReducers );
    const createRateCardData = useSelector((state) => state.rateCardCreateReducers );


    useEffect(() => {

        dispatch(getAllAgeGroupList())
        dispatch(getAllRegionList())
        dispatch(getAllStateList())
        dispatch(getAllCountryList())
        dispatch(getAllNicheList())


    }, []);

    useEffect(()=>{
        if (countries.status == 200 && states.status == 200 && ageGroups.status == 200 && niches.status == 200 ){
            if (row) {
                const formattedData = {
                    name: row.name || '',
                    platform: row.platform || '',
                    campaignType: row.campaignType || '',
                    countryId: row.country?.countryId || '',
                    stateId: row.state?.stateId || '',
                    ageGroupId: row.ageGroup?.ageGroupId || '',
                    nicheId: row.niche?.nicheId || '',
                    costPer1k: row.costPer1k || '',
                    brandPenaltyPercent: row.brandPenaltyPercent || '',
                    rateCardId: row.rateCardId || '',
                };

                console.log('Formatted RateCardData:', row);
                setRateCardData(formattedData);
                setMode('update');
            }


            dispatch(getAllAgeGroupListReset())
            dispatch(getAllCountryListReset())
            dispatch(getAllNicheListReset())
            dispatch(getAllStateListReset())

        }
    },[countries, states, ageGroups, niches])


    const handleChange = (e) => {
        const { name, value } = e.target;

        setRateCardData((prevState) => ({
            ...prevState,
            [name]: ["ageGroupId", "countryId", "stateId", "nicheId", "costPer1k", "brandPenaltyPercent"].includes(name)
                ? Number(value) // Convert these fields to numbers
                : value, // Keep others as strings
        }));
    };


    const handleSubmit = () => {
        console.log('mode',mode);
        if (mode === 'create') {
            dispatch(rateCardCreate(rateCardData));
        } else  {
            dispatch(rateCardUpdate(rateCardData));
        }
    };

    useEffect(() => {
        if(updateRateCardData.status === 200){
            Toast(updateRateCardData.message, 'success' )
            dispatch(rateCardUpdateReset())
            handleBack();
        }else if(updateRateCardData.status === 403){
            Toast(updateRateCardData.message, 'error' )
            dispatch(rateCardUpdateReset())
        }else if(updateRateCardData.status){
            Toast(updateRateCardData.message, 'info' )
            dispatch(rateCardUpdateReset())
        }
    },[updateRateCardData])

    useEffect(() => {
        if(createRateCardData.status === 200){
            Toast(createRateCardData.message, 'success' )
            dispatch(rateCardCreateReset())
            handleBack();
        }else if(createRateCardData.status === 403){
            Toast(createRateCardData.message, 'error' )
            dispatch(rateCardCreateReset())
        }else if(createRateCardData.status){
            Toast(createRateCardData.message, 'info' )
            dispatch(rateCardCreateReset())
        }
    },[createRateCardData])


    const handleBack = () => {
        navigate(-1); // Navigates to the previous page
    };

    return (
        <div className=" text-2xl bg-white p-8 shadow-lg rounded-lg ">
            <div className="text-4xl h-16  mb-6 text-center">
                {mode === 'create' ? 'Create' : 'Update'} Rate Card
            </div>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6">

                {/* Name */}
                <div>
                    <label className="block font-medium mb-2">Name<span className="text-red-500">*</span></label>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={rateCardData.name}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    />
                </div>
                {/* Platform Dropdown */}
                <div>
                    <label className="block font-medium mb-2">Platform<span className="text-red-500">*</span></label>
                    <select
                        name="platform"
                        value={rateCardData.platform}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select Platform</option>
                        <option value="YOUTUBE">YouTube</option>
                        <option value="INSTAGRAM">Instagram</option>
                        <option value="TIKTOK">TikTok</option>
                    </select>
                </div>
                {/* Campaign Type Dropdown */}
                <div>
                    <label className="block font-medium mb-2">Campaign Type<span
                        className="text-red-500">*</span></label>
                    <select
                        name="campaignType"
                        value={rateCardData.campaignType}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select Campaign Type</option>
                        <option value="BANNER">Banner</option>
                        <option value="VIDEO">Video</option>
                        <option value="UGC">UGC</option>
                    </select>
                </div>

                {/* Country Dropdown */}
                <div>
                    <label className="block font-medium mb-2">Country<span className="text-red-500">*</span></label>
                    <select
                        name="countryId"
                        value={rateCardData.countryId}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select Country</option>
                        {Array.isArray(countries?.data) &&
                            countries?.data.map((country) => (
                                <option key={country.countryId} value={country.countryId}>
                                    {country.countryName}
                                </option>
                            ))}
                    </select>
                </div>
                {/*/!* State Dropdown *!/*/}
                <div>
                    <label className="block font-medium mb-2">State</label>
                    <select
                        name="stateId"
                        value={rateCardData.stateId}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select State</option>
                        {Array.isArray(states?.data) && states?.data.map((state) => (
                            <option key={state.stateId} value={state.stateId}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/*/!* Age Group Dropdown *!/*/}
                <div>
                    <label className="block font-medium mb-2">Age Group</label>
                    <select
                        name="ageGroupId"
                        value={rateCardData.ageGroupId}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select Age Group</option>
                        {Array.isArray(ageGroups?.data) &&
                            ageGroups?.data.map((group) => (
                            <option key={group.ageGroupId} value={group.ageGroupId}>
                                {group.ageRangeLabel}
                            </option>
                        ))}
                    </select>
                </div>
                {/*/!* Niche Dropdown *!/*/}
                <div>
                    <label className="block font-medium mb-2">Niche</label>
                    <select
                        name="nicheId"
                        value={rateCardData.nicheId}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select Niche</option>
                        {Array.isArray(niches?.data) &&
                            niches?.data.map((niche) => (
                            <option key={niche.nicheId} value={niche.nicheId}>
                                {niche.nicheName}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Cost Per 1k */}
                <div>
                    <label className="block font-medium mb-2">Cost Per 1k<span className="text-red-500">*</span></label>
                    <input
                        type="number"
                        step="0.01"
                        name="costPer1k"
                        placeholder="Cost Per 1k"
                        value={rateCardData.costPer1k}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    />
                </div>
                {/* Brand Penalty Percent */}
                <div>
                    <label className="block font-medium mb-2">Brand Penalty Percent<span
                        className="text-red-500">*</span></label>
                    <input
                        type="number"
                        step="0.01"
                        name="brandPenaltyPercent"
                        placeholder="Brand Penalty Percent"
                        value={rateCardData.brandPenaltyPercent}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    />
                </div>
            </form>
            {/* Submit Button */}
            <div className="text-center mt-8">
                <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={
                        !rateCardData.name ||
                        !rateCardData.platform ||
                        !rateCardData.campaignType ||
                        !rateCardData.countryId ||
                        !rateCardData.costPer1k ||
                        !rateCardData.brandPenaltyPercent
                    }
                    className={`${
                        mode === 'create' ? 'bg-green-500' : 'bg-blue-500'
                    } hover:opacity-90 text-white py-2 px-6 mr-4 rounded-lg text-2xl ${
                        !rateCardData.name ||
                        !rateCardData.platform ||
                        !rateCardData.campaignType ||
                        !rateCardData.countryId ||
                        !rateCardData.costPer1k ||
                        !rateCardData.brandPenaltyPercent
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    }`}
                >
                    {mode === 'create' ? 'Create' : 'Update'}
                </button>
                <button
                    type="button"
                    onClick={handleBack}
                    className="bg-gray-500 hover:opacity-90 text-white py-2 px-6 rounded-lg"
                >
                    Back
                </button>
            </div>
        </div>
    );
}

export default RateCardForm;
