import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import { influencerList, influencerListReset } from "../../store/actions/influencerActions/influencerListActions";
import {
    approvedRejectedInfluencer,
    approvedRejectedInfluencerReset
} from "../../store/actions/influencerActions/approvedRejectedInfluencerActions";

Modal.setAppElement('#root'); // Set the root element to prevent screen readers from reading the modal content.

function Influencer() {
    const dispatch = useDispatch();
    const influencerReducer = useSelector(state => state.influencerListReducers);
    const approvedRejectedInfluencerReducer = useSelector(state => state.approvedRejectedInfluencerReducers)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [statusFilter, setStatusFilter] = useState('PENDING');

    useEffect(() => {
        dispatch(influencerList({statusFilter:statusFilter}));
    }, [statusFilter]);

    useEffect(() => {
        if (influencerReducer?.status === 200) {
            console.log("influencer data", influencerReducer);
            dispatch(influencerListReset());
        } else if (influencerReducer?.status) {
            dispatch(influencerListReset());
        }
    }, [influencerReducer]);

    useEffect(() => {
        if (approvedRejectedInfluencerReducer?.status === 200) {
            console.log("influencer data", approvedRejectedInfluencerReducer);
            dispatch(influencerList({statusFilter:statusFilter}));
            dispatch(approvedRejectedInfluencerReset());
        } else if (approvedRejectedInfluencerReducer?.status) {
            dispatch(approvedRejectedInfluencerReset());
        }
    }, [approvedRejectedInfluencerReducer]);

    const openApproveModal = (data) => {
        setSelectedInfluencer(data);
        setIsApproveModalOpen(true);
    };

    const openRejectModal = (data) => {
        setSelectedInfluencer(data);
        setIsRejectModalOpen(true);
    };

    const closeApproveModal = () => {
        setIsApproveModalOpen(false);
        setSelectedInfluencer(null);
    };

    const closeRejectModal = () => {
        setIsRejectModalOpen(false);
        setSelectedInfluencer(null);
    };

    const handleApprove = () => {
        if (selectedInfluencer) {
            console.log('Approved influencer ID:', selectedInfluencer);
            dispatch(approvedRejectedInfluencer([{
                "influencerId": selectedInfluencer.influencerId,
                "status": "APPROVED"
            }]));
            closeApproveModal();
        }
    };

    const handleReject = () => {
        if (selectedInfluencer) {
            console.log('Rejected influencer ID:', selectedInfluencer);
            dispatch(approvedRejectedInfluencer([{
                "influencerId": selectedInfluencer.influencerId,
                "status": "REJECTED"
            }]));
            closeRejectModal();
        }
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };


    const columns = [
        {
            name: 'Influencer Id',
            selector: row => row.influencerId,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country?.countryName?row.country?.countryName:'----',
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber?row.phoneNumber:'----',
            sortable: true,
        },
        ...(statusFilter === 'PENDING'
            ? [{
                name: 'Actions',
                width: '200px', // Set a specific width for the "Actions" column
                cell: row => (
                    <div className="flex items-center justify-center space-x-2 w-full">
                        <button
                            onClick={() => openApproveModal(row)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Approve
                        </button>
                        <button
                            onClick={() => openRejectModal(row)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Reject
                        </button>
                    </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }]
            : []),
    ];





    return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen p-8 font-sans">
            {/* Header */}
            <div className="flex items-center justify-between bg-white rounded-lg p-6 shadow-md mb-6">
                <h1 className="m-0 text-[2.4rem] font-bold text-gray-800">Influencer</h1>

            </div>

            <div className="mb-4 text-2xl flex items-center">
                <label className="mr-4 font-semibold text-gray-700">Filter by status:</label>
                <select
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-200 focus:outline-none transition-colors duration-300 ease-in-out"
                >
                    <option value="PENDING">PENDING</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                </select>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
            {Array.isArray(influencerReducer?.data) && influencerReducer.data.length > 0 ? (
                <DataTable
                    columns={columns}
                    data={Array.isArray(influencerReducer?.data) ? influencerReducer.data : []}
                    pagination
                    highlightOnHover
                    theme="default"
                    customStyles={{
                        rows: {
                            style: {
                                fontSize: '16px',
                                color: '#4a4a4a',
                                fontWeight: 500,
                            },
                        },
                        headCells: {
                            style: {
                                fontSize: '18px',
                                fontWeight: 600,
                                backgroundColor: '#f1f5f9',
                                color: '#2c3e50',
                            },
                        },
                    }}
                />
            ) : (
                <div className="text-gray-600 text-[2.5rem] text-center mt-8">
                    There are no records to display.
                </div>
            )}
            </div>

            {/* Approval Confirmation Modal */}
            <Modal
                isOpen={isApproveModalOpen}
                onRequestClose={closeApproveModal}
                contentLabel="Confirm Approve"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                    },
                }}
            >
                <div className="text-[2rem] mb-5 font-bold">Confirm Approval</div>
                <p className="text-[2rem] mb-5">Are you sure you want to approve this influencer?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleApprove}
                            className="bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeApproveModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>

            {/* Rejection Confirmation Modal */}
            <Modal
                isOpen={isRejectModalOpen}
                onRequestClose={closeRejectModal}
                contentLabel="Confirm Reject"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                    },
                }}
            >
                <div className="text-[2rem] mb-5 font-bold">Confirm Rejection</div>
                <p className="text-[2rem] mb-5">Are you sure you want to reject this influencer?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleReject}
                            className="bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeRejectModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Influencer;
